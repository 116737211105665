import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import Navegacion from "./componentes/Navbar/Navbar";
import Footer from "./componentes/Footer/Footer";
import ScrollToTop from "./utils/scrollTop";
import WhatsappButton from "./componentes/WhatsappButton/WhatsaappButton";
import Loader from "./componentes/Loader";



const Home = lazy(() => import("./vistas/Home/Home"));
const Clientes = lazy(() => import("./vistas/Clientes/Clientes"));
const Tarifas = lazy(() => import("./vistas/Tarifas/Tarifas"));
const QuienesSomos = lazy(() => import("./vistas/QuienesSomos/QuienesSomos"));
const Unirse = lazy(() => import("./vistas/Unirse/Unirse"));
const Contacto = lazy(() => import("./vistas/Contacto/Contacto"));


function App() {
  return (
    <div className="app">
      <Navegacion />
      <ScrollToTop />
      <Suspense fallback={<Loader/>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quienes-somos" element={<QuienesSomos />} />
          <Route path="/tarifas" element={<Tarifas />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/unirse" element={<Unirse />} />
          <Route path="/contacto" element={<Contacto />} />
        </Routes>
      </Suspense>
      <Footer />
      <WhatsappButton />
    </div>
  );
}

export default App;
