import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./Navbar.css"
// import logoImage from '../../imagenes/card2.png';
import logoImage from '../../imagenes/coche.png';
import { useState } from 'react';

function Navegacion() {
  const [expanded, setExpanded] = useState(false);

  // const handleNavCollapse = () => {
  //   setExpanded(false);  // Cerrar el menú al hacer clic en un enlace
  // };

  const handleNavCollapse = () => {
    if (expanded) {
      setExpanded(false); // Solo cerrar el menú si está actualmente expandido
    }
  };
  return (
    <Navbar expand="lg" expanded={expanded} className="navbar__container bg-dark sticky-top">
      <Container >
        <Navbar.Brand as={Link} to="/" className='navbar__brand' onClick={handleNavCollapse}>
        {/* <img
            src={logoImage}
            alt="Logo"
            width="110"
            // height="50"
            className="d-inline-block align-top navbar__brand--img"
          />{' '} */}
          <div className='text-light'>LOGO AGENCIA</div>
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='navbar__toggle bg-secondary' onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav"  onClick={handleNavCollapse}>
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/tarifas" className='navbar__link'>Tarifas</Nav.Link>
            <Nav.Link as={Link} to="/quienes-somos" className='navbar__link'>Quienes somos</Nav.Link>       
            <Nav.Link as={Link} to="/unirse" className='navbar__link'>Unirse</Nav.Link>
            <Nav.Link as={Link} to="/contacto" className='navbar__link'>Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navegacion;

